class Position {
  constructor () {
    this.id = null
    this.name = null
    this.description = null
  }

  toString () {
    return `Position{id=${this.id}, name='${this.name}', description='${this.description}'}`
  }
}
export default Position
