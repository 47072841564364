<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="positions"
      :page.sync="page"
      :items-per-page="positionsPerPage"
      :server-items-length="totalPositions"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Positions</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">New Position</v-btn>
            </template>
            <v-card>
              <validation-observer ref="form">
                  <form @submit.prevent="onSubmit">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Position Name"
                        >
                        <v-text-field
                          v-model="editedPosition.name"
                          label="Name"
                          class="mt-n6"
                          outlined
                          name="Position Name"
                          :error-messages="errors"
                          color="grey"
                          filled
                        />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Position Description"
                        >
                        <v-textarea
                          v-model="editedPosition.description"
                          label="Description"
                          class="mt-n6"
                          outlined
                          name="Position Description"
                          :error-messages="errors"
                          color="grey"
                          filled
                        >
                        </v-textarea>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn class="success ml-4 mb-4" text type="submit">Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editPosition(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="positionDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this position ?</p>
          <v-btn link small color="blue" @click="deletePosition">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectPositionsPerPage"
            v-model="positionsPerPage"
            label="Positions per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Position from '@/models/Position'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    positionIndex: null,
    positionItem: null,
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Descriptions', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    positions: [],
    editedIndex: -1,
    editedPosition: new Position(),
    defaultItem: new Position(),
    selectPositionsPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    positionsPerPage: 20,
    totalPositions: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Position' : 'Edit Position'
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.initialize()
      }
    },
    positionsPerPage: {
      handler () {
        this.page = 1
        this.initialize()
      }
    }
  },

  created () {
    this.initialize()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    initialize () {
      const pageNumber = this.page - 1
      this.$userService.fetchAllPositions(pageNumber, this.positionsPerPage)
        .then(response => {
          if (!response.status) {
            this.positions = response.content
            this.totalPositions = response.totalElements
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editPosition (item) {
      this.editedIndex = this.positions.indexOf(item)
      this.$userService.fetchPositionById(item.id)
        .then(response => {
          if (response.id) {
            this.editedPosition = response
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    positionDialog (item) {
      this.positionIndex = this.positions.indexOf(item)
      this.positionItem = item
      this.deleteDialog = true
    },

    deletePosition () {
      this.deleteDialog = false
      this.$userService.deletePosition(this.positionItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.positions.splice(this.positionIndex, 1)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedPosition = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1) {
          this.updatePosition()
        } else this.createPosition()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updatePosition () {
      const deletedPositionArray = this.positions.splice(this.editedIndex, 1)
      this.$userService.savePosition(this.editedPosition)
        .then(response => {
          if (!response.status) {
            this.positions.splice(this.editedIndex, 1, response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.positions.splice(this.editedIndex, 0, deletedPositionArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createPosition () {
      this.$userService.savePosition(this.editedPosition)
        .then(response => {
          if (!response.status) {
            this.positions.push(response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_POSITIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_POSITIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_POSITIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
